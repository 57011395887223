.root[value] {
  @apply relative w-full h-2 border-0 appearance-none bg-khaki shadow-none rounded-full;
}

/* .root above has similar styles to work in Firefox */
.root[value]::-webkit-progress-bar {
  @apply bg-khaki shadow-none rounded-full;
}

/* intentionally not combining the following css because it breaks the styling in chrome */
.root[value]::-webkit-progress-value {
  @apply transition-all rounded-full bg-gradient-yellow-to-purple;
}

.root[value]::-moz-progress-bar {
  @apply transition-all rounded-full bg-gradient-yellow-to-purple;
}
