.slider {
  @apply flex items-center gap-x-2 py-0.5 cursor-grab;

  mask-image: linear-gradient(
    to right,
    transparent,
    #000 var(--mask-left, 0px),
    #000 calc(100% - var(--mask-right, 32px)),
    transparent
  );
}

.button {
  transition-property: border-color;

  @apply flex items-center justify-center shrink-0 border text-center border-khaki-3 w-20 py-3 rounded-lg cursor-pointer duration-200 ease-in-out text-slate-1;
}
