.root [data-reach-accordion] {
  @apply flex flex-col gap-4;
}

.root [data-reach-accordion-item] {
  @apply border border-khaki-5 rounded-2xl;
}

.root [data-reach-accordion-button] {
  @apply flex items-center justify-between w-full p-6 border-none bg-none text-left cursor-pointer gap-2 outline-none;
}

.root [data-reach-accordion-button] svg {
  @apply transition-transform shrink-0;
}

.root
  [data-reach-accordion-item][data-state="open"]
  [data-reach-accordion-button] {
  @apply pb-0;
}

.root
  [data-reach-accordion-item][data-state="open"]
  [data-reach-accordion-button]
  svg {
  @apply rotate-180;
}

.root [data-reach-accordion-panel] {
  @apply p-6 text-base leading-6;
}
