.root {
  width: 52px;
  --track-height-px: 2px;
  --knob-size-px: 12px;
}

.track,
.track > * {
  cursor: pointer;
  touch-action: pan-y;
}

.track_base {
  height: var(--track-height-px);
}

.track_filled {
  width: calc(var(--progress-filled) * 100%);
  height: var(--track-height-px);
}

.knob {
  left: calc(var(--progress-filled) * 100%);
  width: var(--knob-size-px);
  height: var(--knob-size-px);
}
