.slider {
  @apply relative;

  --track-half-height: calc(var(--track-height) / 2);

  height: var(--track-height);
  margin: calc(var(--thumb-size) / 2 - var(--track-half-height)) 0;
}

.track,
.range,
.thumb,
.dot {
  @apply absolute;
}

.track,
.range {
  @apply inset-y-0 left-0;

  border-radius: calc(var(--track-height) / 2);
}

.track {
  @apply z-10 right-0 bg-purple-1/10;
}

.input:disabled ~ .track {
  @apply bg-slate/10 opacity-25;
}

.range {
  @apply z-20 bg-purple-1;

  width: var(--range-width);
}

.input:disabled ~ .range {
  @apply bg-slate opacity-25;
}

.dot {
  @apply z-30 rounded-full bg-white;

  top: calc(var(--track-half-height) / 2);
  bottom: calc(var(--track-half-height) / 2);
  left: var(--dot-left);
  width: var(--track-half-height);
  height: var(--track-half-height);
}

.thumb {
  @apply text-xs font-bold font-lato text-slate bg-khaki-1 rounded-full justify-center items-center z-30 flex;

  left: var(--thumb-left);
  width: var(--thumb-size);
  height: var(--thumb-size);
  transform: translateY(calc(-50% + var(--track-half-height)));
}

.input:disabled ~ .thumb {
  @apply text-opacity-25;
}

.input,
.input::-webkit-slider-thumb {
  @apply appearance-none;

  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.input {
  @apply inset-0 pointer-events-none bg-transparent outline-none z-40 absolute;
}

.input:disabled {
  @apply opacity-25;
}

/*  for chrome browsers, do not combine */
.input::-webkit-slider-thumb {
  @apply relative rounded-full bg-transparent cursor-pointer shadow-md;

  width: var(--thumb-size);
  height: var(--thumb-size);
  pointer-events: all;
}

.input:focus::-webkit-slider-thumb {
  @apply outline-none shadow-lg;
}

.input:disabled::-webkit-slider-thumb {
  @apply cursor-default;
}

/*  for mozilla browsers, do not combine */
.input::-moz-range-thumb {
  @apply relative border border-khaki rounded-full bg-transparent cursor-pointer shadow-md;

  width: var(--thumb-size);
  height: var(--thumb-size);
  pointer-events: all;
}

.input:focus::-moz-range-thumb {
  @apply outline-none shadow-lg;
}

.input:disabled::-moz-range-thumb {
  @apply cursor-default;
}
