.root {
  --gutter: 1.5rem;

  grid-template-columns:
    [full-start]
    minmax(var(--gutter), 1fr)
    [container-start]
    minmax(1rem, var(--container))
    [container-end]
    minmax(var(--gutter), 1fr)
    [full-end];
}

@screen md {
  .fluidGutters {
    --gutter: 3rem;
  }
}

.scroll {
  grid-column: full;
  grid-template-columns: inherit;
}

.container {
  grid-column: container;
  gap: var(--item-gap);
}

.container::after {
  @apply self-stretch content-[""];
}

.fluidGutters .container::after {
  padding-right: calc((100vw - 100%) / 2 - var(--item-gap));
}

.defaultGutters .container::after {
  padding-right: calc(var(--item-gap) / 2);
}
